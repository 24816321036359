import React, { useState } from 'react';
import './FAQ.css';

function FAQ() {
    const [faqs, setfaqs] = useState([
      {
        question: 'What is CPU delidding?',
        answer: 'Delidding is the process of removing the Integrated Heat Spreader (IHS) from the CPU to improve thermal performance by directly replacing the thermal interface material (TIM) with a higher quality one.',
        open: false
      },
      {
        question: 'Why should I delid my CPU?',
        answer: 'Delidding your CPU can significantly reduce temperatures, allowing for better overclocking and overall performance. It helps prevent thermal throttling and increases the longevity of your CPU.',
        open: false
      },
      {
        question: 'Will delidding void my CPU warranty?',
        answer: 'Yes, delidding your CPU will void its warranty. It is considered a modification that is not covered by the manufacturer’s warranty.',
        open: false
      },
      {
        question: 'Do you offer a warranty on delidded CPUs?',
        answer: 'We offer a limited warranty on our delid services and parts. However, we cannot provide a warranty on the CPU itself, as delidding voids the manufacturer’s warranty.',
        open: false
      },
      {
        question: 'How much temperature improvement can I expect?',
        answer: 'Temperature improvements vary depending on the CPU model and the quality of the thermal paste applied. On average, users can expect to see a 10-20°C drop in temperatures.',
        open: false
      },
      {
        question: 'Can I delid my CPU myself?',
        answer: 'While it is possible to delid your CPU yourself, it requires specialized tools and experience. Improper delidding can damage your CPU. We recommend using professional services to avoid any risks.',
        open: false
      },
      {
        question: 'What delid tools do you recommend?',
        answer: 'We recommend using a dedicated delidding tool designed for your specific CPU model. These tools are designed to safely and effectively remove the IHS without damaging the CPU.',
        open: false
      },
      {
        question: 'Do you sell pre-delidded CPUs?',
        answer: 'Yes, we offer a range of pre-delidded CPUs for sale. Each CPU is carefully delidded, tested, and verified for optimal performance.',
        open: false
      },
      {
        question: 'How do I apply thermal paste after delidding?',
        answer: 'After delidding, you should apply a small amount of high-quality thermal paste directly to the CPU die. Spread it evenly for the best thermal performance before reattaching the IHS or using a direct-die cooling solution.',
        open: false
      },
      {
        question: 'What payment methods do you accept?',
        answer: 'We accept all major credit cards, PayPal, and cryptocurrency payments. We also offer financing options through select partners.',
        open: false
      }
    ]);

  const toggleFAQ = index => {
    setfaqs(faqs.map((faq, i) => {
      if (i === index) {
        faq.open = !faq.open;
      } else {
        faq.open = false;
      }
      return faq;
    }));
  }

  return (
    <div className='outer-faqs'>
      <h1 className="pageTitle">Frequently Asked Questions</h1>

      <div className='faqs'>
        {faqs.map((faq, i) => (
          <div 
            className={`faq ${faq.open ? 'open' : ''}`} 
            key={i}
            onClick={() => toggleFAQ(i)}
          >
            <div className='faq-question'>
              {faq.question}
            </div>
            <div className='faq-answer'>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
