import React from 'react';
import './footer.css';
import { useTranslation } from 'react-i18next';

function Footer() {
const { t } = useTranslation();

    return (
        <div className="footer">
            <ul>
                <li><a className="textFoo" href="/faq">{t('foo1')}</a></li>
                <li><a className="textFoo" href="/contact">{t('foo2')}</a></li>
                <li><a className="textFoo" href="/guarantee">{t('foo3')}</a></li>
                <li><a className="textFoo" href="/privacy">{t('foo4')}</a></li>
                <li><a className="textFoo" href="/terms">{t('foo5')}</a></li>
            </ul>
        </div>
    );
};

export default Footer;