import React from 'react';
import styled from 'styled-components';
import Contact from '../Contact';

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  h1 {
    width: 100%;
    text-align: center;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  height: 800px;
  width: 50%;
  align-items: center;
  img {
    flex-grow: 1;
    width: 50%;
    height: 100%;
    background-color: red;
    
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
  
  p {
    height: 50%;
    font-size: 22px;
  }
`;
const LeftContainer = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
// Custom styling for the form inside the Contact component
const StyledContact = styled(Contact)`
  form {
    border: none ;
    padding: 20px;
    border-radius: 8px;
  }
`;

function DelidLapping() {
  return (
    <OuterContainer>
      <h1 className="pageTitle">Delid and Lapping</h1>
      <InnerContainer>
      <RightContainer>
        <p>Looking for expert delid and lap
            ping services for your CPU?<br /> Unlock the full potential of your processor with
             our professional delid and lapping services. Delidding your CPU removes the factory thermal inte
             rface material, allowing us to apply high-performance thermal paste and liquid metal for optimal c
             ooling efficiency.<br /> Our meticulous lapping process ensures a perfectly flat surface, enhancing
              thermal conductivity and improving overall performance. Whether you’re aiming for higher overcloc
              king potential or simply better thermal management, our services are designed to meet your needs
              .<br /> To get a detailed quote for our delid and lapping services, please contact us using the form
               below or reach out to us via Telegram.<br /> We’re here to provide you with the highest quality serv
               ice and help you achieve the best performance from your hardware.
</p>

          <StyledContact />
        </RightContainer>
        <LeftContainer>
        <p>PLACEHOLDER FOR PHOTO</p>
        </LeftContainer>

      </InnerContainer>
    </OuterContainer>
  );
}

export default DelidLapping;
