import React from 'react';
import styled from 'styled-components';
import Contact from '../Contact';

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  h1 {
    width: 100%;
    text-align: center;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  height: 800px;
  width: 50%;
  align-items: center;
  img {
    flex-grow: 1;
    width: 50%;
    height: 100%;
    background-color: red;
    
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
  
  p {
    height: 50%;
    font-size: 22px;
  }
`;
const LeftContainer = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
// Custom styling for the form inside the Contact component
const StyledContact = styled(Contact)`
  form {
    border: none ;
    padding: 20px;
    border-radius: 8px;
  }
`;

function PCBuilding() {
  return (
    <OuterContainer>
      <h1 className="pageTitle">PCBuilding</h1>
      <InnerContainer>
        <LeftContainer>
        <p>PLACEHOLDER FOR PHOTO</p>
        </LeftContainer>
        <RightContainer>
        <p>Looking for a custom-built PC tailored to your exact needs? Whether you're a gamer seeking maximum performance, a content creator needing powerful rendering capabilities, or a tech enthusiast dreaming of the ultimate setup, we’ve got you covered. <br /><br />

Our expert team will work closely with you to design and build a PC that perfectly matches your requirements, ensuring optimal performance, reliability, and style. <br /><br />

From selecting the right components to fine-tuning for overclocking, we handle every detail to bring your vision to life. <br /><br />

To get a personalized quote, please contact us using the form below or reach out via Telegram.
</p>
          {/* Using the styled version of Contact */}
          <StyledContact />
        </RightContainer>
      </InnerContainer>
    </OuterContainer>
  );
}

export default PCBuilding;
