// LanguageSwitcher.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language || 'en'; // Default to 'en' if i18n.language is undefined

  const handleChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <div>
      <select value={currentLanguage} onChange={handleChange}>
        <option value="en">English</option>
        <option value="pl">Polski</option>
        <option value="es">Español</option>
        <option value="de">Deutsch</option> {/* Fixed the language code to 'de' */}
      </select>
    </div>
  );
};

export default LanguageSwitcher;
