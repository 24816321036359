import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styled from 'styled-components';
import pcbanner from '../images/pcbanner.png';
import pcoptimization from '../images/pcoptimization.png';
// Wrapper for the carousel to control its width
const CarouselWrapper = styled.div`
    width: 30%;
    margin: 0 auto;
    @media (max-width: 768px) {
        width: 80%; /* Set to 100% width on devices 768px or less */
    }
`;

// Styled component for the Carousel container
const StyledCarousel = styled(Carousel)`
  .react-multi-carousel-list {
    width: 100%; /* Make sure list width adjusts to carousel width */
  }

  .react-multi-carousel-track {
    display: flex;
    justify-content: center; /* Center the track content */
  }

  .react-multi-carousel-item {
    display: flex;
    justify-content: center; /* Center the item itself */
    background-color: black;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-right: 0;
  }

  .custom-dot-list-style {
    bottom: -20px;
  }

  .react-multi-carousel-arrow {
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 1;
  }

  .react-multi-carousel-arrow::before {
    color: black; /* Arrow icon color */
  }

  .react-multi-carousel-arrow--left {
    left: -45px; /* Position left arrow closer */
  }

  .react-multi-carousel-arrow--right {
    right: -45px; /* Position right arrow closer */
  }
`;

// Styled component for individual items
const CarouselItem = styled.div`
  display: flex;
  justify-content: center; /* Center the content */
  align-items: center; /* Center the content vertically */
  color: white;
  border-radius: 10px;
  width: 100%; /* Full width of the carousel */
  padding: 20px;
  text-align: center;
  font-size: 18px;
`;

// Styled component for the image inside CarouselItem
const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensures the image scales correctly */
`;

function ADBanner() {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1, // Only one item displayed
            slidesToSlide: 1, // Slides one item at a time
            partialVisibilityGutter: 0 // this is needed to tell the amount of px that should be visible.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1, // Only one item displayed
            slidesToSlide: 1, // Slides one item at a time
            partialVisibilityGutter: 0 // this is needed to tell the amount of px that should be visible.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1, // Only one item displayed
            slidesToSlide: 1, // Slides one item at a time
            partialVisibilityGutter: 0 // this is needed to tell the amount of px that should be visible.
        }
    };

    return (
        <CarouselWrapper>
            <StyledCarousel 
                infinite={true}
                responsive={responsive}
                removeArrowOnDeviceType={["desktop","tablet", "mobile"]}
                swipeable={false}
                draggable={false}
                showDots={false}
                autoPlay={true}
                autoPlaySpeed={10000}
                dotListClass="custom-dot-list-style"
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
            >
                <CarouselItem>
                    <Image src={pcbanner} alt="PC Banner" />
                </CarouselItem>
                <CarouselItem>
                    <Image src={pcoptimization} alt="PC Banner" />

                </CarouselItem>
                <CarouselItem>placeholder 3</CarouselItem>
                <CarouselItem>placeholder 4</CarouselItem>
            </StyledCarousel>
        </CarouselWrapper>
    );
}

export default ADBanner;
