// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './translations/enTranslation.json';
import plTranslation from './translations/plTranslation.json';
import deTranslation from './translations/deTranslation.json';
import esTranslation from './translations/esTranslation.json';

// Initialize i18next
i18n
  .use(LanguageDetector) // Add language detector
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      pl: {
        translation: plTranslation
      },
      es: {
        translation: esTranslation
      },
      de: {
        translation: deTranslation
      }
    },
    fallbackLng: 'en', // Default language if no language is detected
    interpolation: {
      escapeValue: false
    },
    detection: {
      // Configure language detection to use local storage
      caches: ['localStorage'], // Cache language in localStorage
      lookupLocalStorage: 'i18nextLng' // Key for localStorage
    }
  });

export default i18n;
