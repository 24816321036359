import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import { ShopContextProvider } from './context/shop-context';

import Navbar from './components/navbar';
import Footer from './components/footer';
import FAQ from './components/FAQ';
import About from './components/About';
import ContactForm from './components/ContactForm';
import DelidLapping from './components/services/DelidLapping';
import LMPaste from './components/services/LMPaste';
import Overclocking from './components/services/Overclocking';
import PCBuilding from './components/services/PCBuilding';
import Products from './products/Products';
import ProductDetails from './products/ProductDetails';
import Cart from './components/Cart';

function App() {
  return (
    <ShopContextProvider>

      <Router>
        <div className="app">
          <Navbar />
          <main>
            <Routes>
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<ContactForm />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/pcbuilding" element={<PCBuilding />} />
              <Route path="/delid&lapping" element={<DelidLapping />} />
              <Route path="/lm&paste" element={<LMPaste />} />
              <Route path="/overclocking" element={<Overclocking />} />
              <Route path="/products" element={<Products />} />
              <Route path="/product/:id" element={<ProductDetails />} />
              <Route path="/cart" element={<Cart />} />

            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ShopContextProvider>

  );
}

export default App;
