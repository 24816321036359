import React, { useState } from 'react';


const Contact = ({className}) => {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: ''
    });
    const [status, setStatus] = useState('');
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
    
      try {
        const response = await fetch('/api/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
    
        if (response.ok) {
          setStatus('Message sent successfully!');
          setFormData({ name: '', email: '', message: '' });
        } else {
          setStatus('Failed to send message.');
        }
      } catch (error) {
        setStatus('An error occurred.');
      }
    };
              return (  
              <div className={className}>
              <form onSubmit={handleSubmit}>
                        <label>
                            Name:
                            <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            />
                        </label>
                        <br />
                        <label>
                            Email:
                            <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            />
                        </label>
                        <br />
                        <label>
                            Message:
                            <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            />
                        </label>
                        <br />
                        <div className="bottomFormPart">
                            <button type="submit">Send</button>
                            <span>Average response time ~ 12h</span>
                        </div>
                        {status && <p>{status}</p>}

                        </form>
                        </div>
              )
};

    
export default Contact;