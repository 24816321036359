import React, { useEffect } from 'react';
import './navbar.css';
import logo from '../images/logov2.png';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';
import {Link} from 'react-router-dom';
import { ShoppingCart } from 'phosphor-react';

function Navbar() {
    const { t } = useTranslation();

    useEffect(() => {
        // This will run after the component has mounted
        const navbar = document.querySelector('.outerNav');
        
        if (navbar) {
            const sticky = navbar.offsetTop;

            const handleScroll = () => {
                if (window.scrollY >= sticky) {
                    navbar.classList.add('sticky');
                } else {
                    navbar.classList.remove('sticky');
                }
            };

            // Set the scroll event listener
            window.addEventListener('scroll', handleScroll);

            // Cleanup the event listener on component unmount
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        } else {
            console.error('Navbar element not found!');
        }
    }, []); // Empty dependency array to run only once on mount

    return (
        <div className='outerNav'>
            <div className="navbar">

                <ul>
                <img className="title-photo"src={logo} alt="Logo" />

                    <li><Link className="textNav" to="/home">{t('nav1')}</Link></li>
                    <li><Link className="textNav" to="/about">{t('nav2')}</Link></li>
                    <li><Link className="textNav" to="/products">{t('nav3')}</Link></li>
                    <li className="dropdown">
                        <Link className="textNav" to="#">{t('nav4')}</Link>
                        <ul className="dropdown-menu">
                            <li><Link to="/pcbuilding">PC Building</Link></li>
                            <li><Link to="/delid&lapping">Delid & Lapping</Link></li>
                            <li><Link to="/lm&paste">Replacing LM & Paste</Link></li>
                            <li><Link to="/overclocking">Overclocking</Link></li>
                        </ul>
                    </li>
                    <li><Link className="textNav" to="/contact">{t('nav5')}</Link></li>
                    <li><Link className="textNav" to="/cart"><ShoppingCart color='green' size={32}/></Link></li>

                    <LanguageSwitcher />
                </ul>
            </div>
        </div>
    );
}

export default Navbar;
