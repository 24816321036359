// Cart.jsx
import React, { useContext, useEffect, useState } from 'react';
import { ShopContext } from '../context/shop-context';
import styled from 'styled-components';
import { Plus, Minus } from 'phosphor-react';

const OuterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`;

const ContainerUl = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

const ContainerLi = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    div {
        width: 30%;
    }
`;

const customizationPrices = {
    liquidmetal: 80,
    relid: 60,
};

const Cart = () => {
    const { cartItems, removeFromCart, increaseQuantity, decreaseQuantity } = useContext(ShopContext);
    const [products, setProducts] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchProducts = async () => {
            const productIds = Array.from(new Set(Object.keys(cartItems).map(key => key.split('-')[0])));
            if (productIds.length > 0) {
                try {
                    const responses = await Promise.all(
                        productIds.map(id =>
                            fetch(`http://localhost:5000/api/products/${id}`).then(response => response.json())
                        )
                    );
                    const validProducts = responses.filter(product => product && product.id);
                    setProducts(validProducts);
                } catch (error) {
                    console.error('Error fetching products:', error);
                }
            }
        };

        fetchProducts();
    }, [cartItems]);

    const calculateTotalPrice = () => {
        return Object.keys(cartItems).reduce((total, key) => {
            const item = cartItems[key];
            const quantity = item.quantity || 0;
            const basePrice = parseFloat(item.price) || 0;
            return total + (basePrice * quantity);
        }, 0);
    };

    const productMap = products.reduce((map, product) => {
        map[product.id] = product;
        return map;
    }, {});

    const handleCheckout = async () => {
        // Check stock availability before proceeding
        let stockError = '';
        Object.keys(cartItems).forEach(key => {
            const item = cartItems[key];
            const productId = key.split('-')[0];
            const product = productMap[productId];
            if (product) {
                const quantity = item.quantity || 0;
                if (quantity > product.stock) {
                    stockError += `Insufficient stock for ${product.name}. Available: ${product.stock}, Requested: ${quantity}.\n`;
                }
            }
        });

        if (stockError) {
            setErrorMessage(stockError);
            return;
        }

        setErrorMessage(''); // Clear any previous errors
        const totalPrice = calculateTotalPrice();

        try {
            const response = await fetch('http://localhost:5000/api/checkout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    amount: totalPrice,
                    currency: 'USD',
                    orderId: Date.now(),
                }),
            });

            if (response.ok) {
                const data = await response.json();
                window.location.href = data.paymentUrl;
            } else {
                const errorText = await response.text();
                console.error('Payment initiation failed:', errorText);
                setErrorMessage('Payment initiation failed. Please try again.');
            }
        } catch (error) {
            console.error('Error initiating payment:', error);
            setErrorMessage('Error initiating payment. Please try again.');
        }
    };

    return (
        <OuterContainer>
            <h2>Cart</h2>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            {Object.keys(cartItems).length === 0 ? (
                <p>Your cart is empty</p>
            ) : (
                <ContainerUl>
                    {Object.keys(cartItems).map(key => {
                        const item = cartItems[key];
                        const productId = key.split('-')[0];
                        const product = productMap[productId];
                        if (!product) return null;
                        const quantity = item.quantity || 0;
                        const basePrice = parseFloat(item.price) || 0;
                        const customizationOption = item.customization?.option;
                        const customizationPrice = customizationPrices[customizationOption] || 0;
                        const totalPriceForItem = (basePrice + customizationPrice) * quantity;
                        return (
                            <ContainerLi key={key}>
                                <div>
                                    <strong>{product.name}</strong> + {customizationOption || 'None'}
                                    <br />Quantity: {quantity} 
                                    <Plus color='green' onClick={() => increaseQuantity(key)} />
                                    <Minus color='red' onClick={() => decreaseQuantity(key)} />
                                    <br />Base Price: ${(basePrice).toFixed(2)}
                                    <br />Customization Price: ${(customizationPrice).toFixed(2)}
                                    <br />Total: ${totalPriceForItem.toFixed(2)}
                                </div>
                                <button onClick={() => removeFromCart(key)}>
                                    Remove
                                </button>
                            </ContainerLi>
                        );
                    })}
                </ContainerUl>
            )}
            <div>
                <h3>Total Price: ${isNaN(calculateTotalPrice()) ? '0.00' : calculateTotalPrice().toFixed(2)}</h3>
                <button onClick={handleCheckout} style={{ marginTop: '20px' }}>
                    Checkout
                </button>
            </div>
        </OuterContainer>
    );
};

export default Cart;
