import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import './ProductDetails.css';
import Product from './Products';
import ADBanner from '../components/ADBanner';
import { ShopContext } from '../context/shop-context.jsx'; // Adjust the path as necessary

const ProductDetails = () => {
  const { addToCart } = useContext(ShopContext); // Use the context to access addToCart function
  const [faqs, setfaqs] = useState([
    {
      question: 'Warranty',
      answer: 'Delidding is the process of removing the Integrated Heat Spreader (IHS) from the CPU to improve thermal performance by directly replacing the thermal interface material (TIM) with a higher quality one.',
      open: false
    },
    {
      question: 'Free Shipping',
      answer: 'Delidding your CPU can significantly reduce temperatures, allowing for better overclocking and overall performance. It helps prevent thermal throttling and increases the longevity of your CPU.',
      open: false
    },
    {
      question: '24/7 Support',
      answer: 'Yes, delidding your CPU will void its warranty. It is considered a modification that is not covered by the manufacturer’s warranty.',
      open: false
    },
    {
      question: 'Personal Video',
      answer: 'Yes, delidding your CPU will void its warranty. It is considered a modification that is not covered by the manufacturer’s warranty.',
      open: false
    },
    
  ]);

  const [specifications, setspecification] = useState([
    {
      question: 'Full specifications',
      answer: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
      open: false
    },
  ]);

  const [product, setProduct] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [showPrice, setShowPrice] = useState(false); // State to control the visibility of the total price

  // Customization option prices
  const optionPrices = {
    nakedChip: 0, // Naked Chip is free
    liquidmetal: 80,
    relid: 60
};

  const toggleFAQ = index => {
    setfaqs(faqs.map((faq, i) => {
      if (i === index) {
        faq.open = !faq.open;
      } else {
        faq.open = false;
      }
      return faq;
    }));
  };

  const toggleSpecification = index => {
    setspecification(specifications.map((specification, y) => {
      if (y === index) {
        specification.open = !specification.open;
      } else {
        specification.open = false;
      }
      return specification;
    }));
  };
  const { id } = useParams(); // Get the product ID from the URL

  useEffect(() => {
    fetch(`http://localhost:5000/api/products/${id}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const price = Number(data.price);
        setProduct(data);
        setTotalPrice(data.price); // Initialize total price with base price
      })
      .catch(error => console.error('Error fetching product details:', error));
  }, [id]);

  const calculateTotalPrice = (option) => {
    const optionPrice = optionPrices[option] || 0;
    const basePrice = product ? Number(product.price) : 0;
    return basePrice + optionPrice;
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    const newTotalPrice = calculateTotalPrice(option);

    // Log basePrice, optionPrice, and newTotalPrice
    console.log('Base Price:', product ? Number(product.price) : 0);
    console.log('Option Price:', optionPrices[option] || 0);
    console.log('New Total Price:', newTotalPrice);

    setTotalPrice(newTotalPrice);
    setShowPrice(optionPrices[option] > 0); // Show the price info only when the option price is greater than zero
  };

  const handleBuyNow = () => {
    console.log('Buy Now clicked with option:', selectedOption, 'Total Price:', totalPrice);
  };
  
  const handleAddToCart = () => {
    if (product) {
      const newTotalPrice = calculateTotalPrice(selectedOption);
  
      addToCart(product.id + '-' + selectedOption, {
        quantity: 1,
        price: newTotalPrice,
        option: selectedOption,
      });
    }
  };
  
  // Determine the stock color based on the stock quantity
  const getStockColor = (stock) => {
    if (stock > 5) return 'green';
    if (stock > 0) return 'yellow';
    return 'rgba(255, 0, 0, 0.4)'; // Out of stock
  };
  
  if (!product) {
    return <div>Loading...</div>;
  }
  
  const stockColor = getStockColor(product.stock);
  
  const breadcrumbs = [
    { name: 'Products', path: '/products' },
    { name: product.name, path: '#' }, // Current product
  ];
  
  const optionPrice = selectedOption ? optionPrices[selectedOption] || 0 : 0;
  
  return (
    <div>
      <nav className="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => (
          <React.Fragment key={index}>
            <a href={breadcrumb.path}>{breadcrumb.name}</a>
            {index < breadcrumbs.length - 1 && <span> &gt; </span>}
          </React.Fragment>
        ))}
      </nav>
      <div className='outer-outer'>
        <div className='product-categories'><Product /></div>
        <div className="outer-details">
          <div className='product-image'>
            <img className="detailsImg" src={product.image_url} alt={product.name} />
            <p className='below-img'>Lorem Ipsum is simply dummy text...</p>
  
            <div className='specification-details'>
              {specifications.map((specification, y) => (
                <div
                  className={`specification-box ${specification.open ? 'open' : ''}`}
                  key={y}
                  onClick={() => toggleSpecification(y)}
                >
                  <div className='specification-question'>
                    {specification.question}
                  </div>
                  {specification.open && (
                    <div className='specification-answer'>
                      {specification.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="product-details">
            <div className='word-details'>
              <h2>New {product.name}</h2>
              <p className='product-video'>Included: Video of scalping process with date</p>
              <p>${product.price}
                {showPrice && (
                  <span className='total-price fade-in'>
                    + ${optionPrice} = ${totalPrice}
                  </span>
                )}
              </p>
              <span className="stock-info" style={{ color: stockColor }}>
                Stock: {product.stock}
              </span>
            </div>
            <div className='customization-options'>
              <p className='select-option'>Select an option:</p>
  
              <div
                className={`customization-option ${selectedOption === 'nakedChip' ? 'selected' : ''}`}
                onClick={() => handleOptionSelect('nakedChip')}
              >
                <h3>Naked Chip</h3>
                <span>Clean, without adhesive <span className='option-price-select'>(Free)</span></span>
              </div>
              <div
                className={`customization-option ${selectedOption === 'liquidmetal' ? 'selected' : ''}`}
                onClick={() => handleOptionSelect('liquidmetal')}
              >
                <h3>Liquid Metal</h3>
                <span>High quality liquid metal on chip, for direct die <span className='option-price-select'>${optionPrices.liquidmetal}</span></span>
              </div>
              <div
                className={`customization-option ${selectedOption === 'relid' ? 'selected' : ''}`}
                onClick={() => handleOptionSelect('relid')}
              >
                <h3>Relid</h3>
                <span className='customization-description'>Reapply IHS with high-quality thermal interface. <span className='option-price-select'>${optionPrices.relid}</span></span>
              </div>
  
              <div className='button-container'>
                <button
                  className="buy-now-button"
                  onClick={handleBuyNow}
                  disabled={!selectedOption || product.stock === 0} // Disable button if no option is selected or stock is 0
                >
                  Buy Now
                </button>
                <button
                  className="add-to-cart-button"
                  onClick={handleAddToCart} // Use the addToCart handler from context
                  disabled={!selectedOption || product.stock === 0} // Disable button if no option is selected or stock is 0
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='right-details'>
          <div className='below-details'>
            {faqs.map((faq, i) => (
              <div
                className={`below-boxes ${faq.open ? 'open' : ''}`}
                key={i}
                onClick={() => toggleFAQ(i)}
              >
                <div className='faq-question'>
                  {faq.question}
                </div>
                {faq.open && (
                  <div className='faq-answer'>
                    {faq.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <ADBanner />
    </div>
  );
  
};

export default ProductDetails;