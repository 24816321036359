import React, { useState } from 'react';
import './ContactForm.css';
import Contact from '../components/Contact'
const ContactForm = () => {

    
  return (
    <div className="outerbox">
      <h1 className="pageTitle">Contact us</h1>
      <div className="formBox">
        <div className="leftFormBox">
          <h2>You can also contact us like this:</h2>
          <p className="contactOptions">Telegram</p>
          <p className="contactOptions">Mail</p>
          <h2>Join our telegram group!</h2>
          <button type="button">@delidall</button>
        </div>
        <div className="rightFormBox">
          <h2>Contact Form</h2>
          <p>Do you have a question about a gaming computer, need support choosing the perfect rig? Already own an Empower Gaming Computer and need some help? Feel free to send us an email, phone call, Text or fill out this form and send it. We will be in contact during our normal business hours.</p>
          <Contact className="contact-form-form"/>
        </div>
      </div>
      <h1>We are working 7 days a week</h1>

    </div>
  );
};

export default ContactForm;
