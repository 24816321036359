import React from 'react';
import styled from 'styled-components';
import Contact from '../Contact';

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  h1 {
    width: 100%;
    text-align: center;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  height: 800px;
  width: 50%;
  align-items: center;
  img {
    flex-grow: 1;
    width: 50%;
    height: 100%;
    background-color: red;
    
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
  
  p {
    height: 50%;
    font-size: 22px;
  }
`;
const LeftContainer = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
// Custom styling for the form inside the Contact component
const StyledContact = styled(Contact)`
  form {
    border: none ;
    padding: 20px;
    border-radius: 8px;
  }
`;

function Overclocking() {
  return (
    <OuterContainer>
      <h1 className="pageTitle">Overclocking</h1>
      <InnerContainer>
      <RightContainer>
        <p>Need expert support for overclocking your PC?<br /> Whether you're pushing your system to new performance heights or troubleshooting existing configurations, our experienced team is here to help.<br /> We provide comprehensive support to ensure your overclocking endeavors are successful and safe. From tuning your settings for peak performance to diagnosing and resolving any issues, we’ve got you covered every step of the way.<br /> To get a personalized quote for our overclocking support services, please reach out to us using the form below or contact us directly via Telegram.<br /> Our team is ready to assist you and ensure your hardware operates at its best.
</p>
          <StyledContact />
        </RightContainer>
        <LeftContainer>
        <p>PLACEHOLDER FOR PHOTO</p>
        </LeftContainer>

      </InnerContainer>
    </OuterContainer>
  );
}

export default Overclocking;
