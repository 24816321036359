import React from 'react';
import styled from 'styled-components';
import performance from '../images/performance.png'
import support from '../images/support.jpeg'
import warranty from '../images/warrantyphoto.png'
// Styled component for the About title
const PageTitle = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    h2{
    width: 100%;
    text-align: center;
    }
    p{
    text-align: center;
    font-size: 24px;
    color: white;
    width: 50%;
    }
  `;

// Styled component for the Pillars section
const PillarsSection = styled.div`
  h2{
  width: 100%;
  text-align: center;
  }
  display: flex;
  justify-content: center;
  margin: 20px;
  padding: 20px;
    background-color: #040404;

  flex-wrap: wrap;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

// Styled component for individual pillar items
const PillarItem = styled.div`
    img{
    width: 100%;
    height: 250px;
    }
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  text-align: center;
  min-width: 300px;
  width: 100%;
  margin-right: 40px;
  max-width: 500px;
`;

const SeeMoreContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  padding-bottom: 40px;
  h2 {
    width: 100%;
    color: white;
  }
`;

const InsideContainer = styled.div`
  width: 30%;
  position: relative; /* Position relative for absolute positioning of the button */
  margin: 10px;
  img {
    border: 5px solid green;
    height: 500px;
    width: 100%; /* Ensures the image scales to the container */
    display: block; /* Removes any extra space below the image */
  }
  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centers the button */
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
`;

// The Pillars component
const Pillars = () => (
  <PillarsSection>
    <h2>Pillars</h2>
    <PillarItem>
      <img src={warranty}/>
      <h2>Warranty</h2>
      <p>We believe in the quality and reliability of our products, which is why all our 
        delidded CPUs and custom PCs come with a comprehensive warranty.<br></br> 
        We take the risk out of overclocking by providing CPUs that are professionally delidded, tested, 
        and covered by our warranty, ensuring that you can push your system to its limits with confidence and 
        peace of mind.</p>
    </PillarItem>
    <PillarItem>
      <img src={performance}/>
      <h2>Performance</h2>
      <p>Performance is at the core of everything we do. Our delidded CPUs are designed to maximize cooling 
        and efficiency, unlocking the full potential of your hardware. <br></br>Whether you're aiming for 
        higher clock speeds or lower temperatures,  our expertly prepared 
        CPUs and custom PC builds are optimized for peak performance.</p>   
         </PillarItem>
    <PillarItem>
      <img src={support}/>
      <h2>Support</h2>
      <p>Our commitment to you doesn't end at the point of sale. We offer ongoing support to 
        help you achieve your overclocking goals safely and effectively. <br></br>From setup guidance to 
        troubleshooting, our team of experienced technicians is here to assist you every step of the way, 
        ensuring that you get the most out of your hardware.</p>    
        </PillarItem>
  </PillarsSection>
);

const SeeMore = () => (
  <SeeMoreContainer>
    <h2>Learn More About Our PCs</h2>
    <InsideContainer>
      <img src='' />
      <button>See more</button>
    </InsideContainer>
    <InsideContainer>
      <img src=''  />
      <button>See more</button>
    </InsideContainer>
    <InsideContainer>
      <img src=''  />
      <button>See more</button>
    </InsideContainer>
  </SeeMoreContainer>
);

// The main About component
function About() {
  return (
    <div>
      <PageTitle>
        <h2 className="pageTitle">About Our Mission</h2>
        <p>At All Delid, our mission is to empower PC enthusiasts and performance seekers with the best hardware and expert services for overclocking. We specialize in providing delidded CPUs that are optimized and ready for overclocking, eliminating the need for you to take on the risky and delicate process of delidding and relidding yourself. We understand that overclocking can push your hardware to its limits, and our delidded CPUs, with premium thermal paste and liquid metal applied, ensure better thermal performance, stability, and longevity.<br /><br />

We are committed to taking the guesswork and danger out of CPU delidding, allowing you to focus on what you love most—unlocking your system’s full potential. Our delidded CPUs are meticulously prepared, tested, and guaranteed to deliver the best possible performance without the risk of damaging your processor.<br /><br />

In addition to offering high-performance delidded CPUs, we provide a full range of custom PC building services tailored to your unique needs and preferences. Whether you’re a hardcore gamer, a professional content creator, or a tech enthusiast looking for the ultimate setup, our expert team is here to help you build the PC of your dreams.<br /><br />

Our services also include replacing thermal paste and liquid metal applications for those looking to maximize cooling efficiency and maintain optimal operating temperatures, critical for achieving the highest overclock speeds.<br /><br />

We stand by our customers with comprehensive support for all your overclocking needs. From guidance on achieving the perfect overclock to troubleshooting any issues that arise, our team of seasoned overclockers and PC experts are ready to provide you with the support and knowledge you need to succeed.<br /><br />

At All Delid, our mission is clear: to provide you with high-performance solutions and peace of mind, so you can push the boundaries of your hardware safely and effectively. Join us on this journey, and let’s break barriers together!
</p>

      </PageTitle>
      <Pillars />
      <SeeMore />
    </div>
  );
}

export default About;
