import React, { useEffect, useState } from 'react';
import './Products.css';
import { Link } from 'react-router-dom'; // Import Link for routing
const Products = () => {
  const [products, setProducts] = useState([]);
  const [faqs, setfaqs] = useState([


    {
      question: 'Intel 14th gen',
      answer: 'Yes, delidding your CPU will void its warranty. It is considered a modification that is not covered by the manufacturer’s warranty.',
      open: false,
      generation: 14, // Add generation property to each FAQ
    },

  {
    question: 'Intel 13th gen',
    answer: 'Delidding your CPU can significantly reduce temperatures, allowing for better overclocking and overall performance. It helps prevent thermal throttling and increases the longevity of your CPU.',
    open: false,
    generation: 13, // Add generation property to each FAQ
  },
  {
    question: 'Intel 12th gen',
    answer: 'Delidding is the process of removing the Integrated Heat Spreader (IHS) from the CPU to improve thermal performance by directly replacing the thermal interface material (TIM) with a higher quality one.',
    open: false,
    generation: 12, // Add generation property to each FAQ
  }
  ]);

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      })
    );
  };

  useEffect(() => {
    fetch('http://localhost:5000/api/products')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => setProducts(data))
      .catch((error) => console.error('Error fetching products:', error));
  }, []);

  return (
      <div className="outer-products">
        <div className="g-product">
          {faqs.map((faq, i) => (
            <div
              className={`faq ${faq.open ? 'open' : ''}`}
              key={i}
              onClick={() => toggleFAQ(i)}
            >
              <div className="generations">{faq.question}</div>
              <div className="generations-products">
                {/* Filter products based on the generation of the FAQ */}
                <div className="products-list">
                  {products
                    .filter((product) => product.generation === faq.generation)
                    .map((product) => (
                      <div key={product.id} className="product-item">

                        <Link
                          to={`/product/${product.id}`}
                        >
                        {product.name}                        
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
  );
};

export default Products;
