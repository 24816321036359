import React, { createContext, useState, useEffect } from 'react';

// Create a context object to hold and provide shared state
export const ShopContext = createContext(null);

export const ShopContextProvider = (props) => {
    const [cartItems, setCartItems] = useState(() => {
        const savedCart = localStorage.getItem('cartItems');
        return savedCart ? JSON.parse(savedCart) : {};
    });

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);
    

    
    const increaseQuantity = (id) => {
        setCartItems((prevCartItems) => ({
          ...prevCartItems,
          [id]: { ...prevCartItems[id], quantity: prevCartItems[id].quantity + 1 },
        }));
      };
    
      const decreaseQuantity = (id) => {
        setCartItems((prevCartItems) => {
          const newQuantity = prevCartItems[id].quantity - 1;
          if (newQuantity > 0) {
            return {
              ...prevCartItems,
              [id]: { ...prevCartItems[id], quantity: newQuantity },
            };
          } else {
            const updatedCartItems = { ...prevCartItems };
            delete updatedCartItems[id]; // Remove the item if quantity is 0
            return updatedCartItems;
          }
        });
      };

    const addToCart = (id, customization = {}) => {
        console.log('Adding to cart with id:', id, 'customization:', customization);
    
        setCartItems(prevItems => {
            // Fetch basePrice from somewhere or assume it's part of the customization
            const basePrice = customization.basePrice || 0;
    
            const existingProduct = prevItems[id];
            const newItems = existingProduct ? {
                ...prevItems,
                [id]: {
                    quantity: existingProduct.quantity + 1,
                    basePrice: basePrice, // Add basePrice here
                    price: customization.price || existingProduct.price,
                    customization,
                }
            } : {
                ...prevItems,
                [id]: {
                    quantity: 1,
                    basePrice: basePrice, // Add basePrice here
                    price: customization.price || 0,
                    customization,
                }
            };
    
            console.log('Updated cart items:', newItems);
    
            return newItems;
        });
    };
    
    
    

    const removeFromCart = (id) => {
        setCartItems(prevItems => {
            const newItems = { ...prevItems };
            delete newItems[id];
            return newItems;
        });
    };

    const getQuantity = (id) => {
        return (cartItems[id] && cartItems[id].quantity) || 0;
    };

    return (
        <ShopContext.Provider value={{ cartItems, addToCart, removeFromCart, getQuantity, increaseQuantity, decreaseQuantity }}>
            {props.children}
        </ShopContext.Provider>
    );
};
