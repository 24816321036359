import React from 'react';
import styled from 'styled-components';
import Contact from '../Contact';

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  h1 {
    width: 100%;
    text-align: center;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  height: 800px;
  width: 50%;
  align-items: center;
  img {
    flex-grow: 1;
    width: 50%;
    height: 100%;
    background-color: red;
    
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
  
  p {
    height: 50%;
    font-size: 22px;
  }
`;
const LeftContainer = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
// Custom styling for the form inside the Contact component
const StyledContact = styled(Contact)`
  form {
    border: none ;
    padding: 20px;
    border-radius: 8px;
  }
`;

function LMPaste() {
  return (
    <OuterContainer>
      <h1 className="pageTitle">LMPaste</h1>
      <InnerContainer>
        <LeftContainer>
        <p>PLACEHOLDER FOR PHOTO</p>
        </LeftContainer>
        <RightContainer>
        <p>
        Need to replace your thermal paste or liquid metal for improved cooling?<br /> 
        Our replacement service ensures your PC runs at its best by renewing the thermal 
        interface materials between your CPU/GPU and their coolers. Whether you need a fresh 
        application of high-performance thermal paste or a new layer of liquid metal, we've 
        got you covered.<br /> Thermal paste and liquid metal play a crucial role in maintaining 
        optimal temperatures and preventing overheating. Over time, these materials can degrade, 
        leading to reduced performance and stability. Our expert team will carefully remove the old 
        material and apply a new, high-quality solution to maximize your system's cooling efficiency and 
        performance.<br /> For a personalized quote and to schedule your service, please contact us using 
        the form below or connect with us via Telegram.<br /> We're dedicated to ensuring your hardware 
        performs at its peak with top-notch thermal management solutions.
        </p>
          <StyledContact />
        </RightContainer>
      </InnerContainer>
    </OuterContainer>
  );
}

export default LMPaste;
